@import "xixia";

body {
  background-image: none;
}

.logo {
  width: 160px;
}

.article {
  a {
    text-decoration: underline;
  }
}

h2 {
    a {
      text-decoration: none !important;
    }
}


.fa {
    color: #ffffff;
    margin-right: 8px;
  }

  img[src*='#center'] {
    display: block;
    margin: auto;
  }

  img[src*='#size'] {
    display: block;
    margin: auto;
    width: 400px;
  }

  #social-media {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .about {
    width: 350px;
    margin-top: 10px;
    margin-right: 30px;
  }

  .start-here {
    width: 200px;
    margin-top: 10px;
    margin-right: 30px;
  }


  .navbar-brand{
    padding: 12px 8px 5px;
    font-size: 28px;
  }

  .related_image {
    width: 220px;
    margin-right: 15px;
    height: 210px;
  }

  .media_container {
    display: flex;
  }

  @media only screen and (max-width: 600px) {
    .media_container {
      flex-direction: column;
      align-items: center;
      .content{
        margin-top: 15px;
      }
      h2{
        margin-top: 0px;
      }
    }
  }

  #mc_embed_signup input.email {
    width: 80% !important;
  }

  #mc_embed_signup .button  {
    background-color: #808080 !important;
  }

#mc_embed_signup label{
  font-weight: 500 !important;
}